/* eslint-disable react-hooks/exhaustive-deps */
import DoctorDetails from 'components/doctor-details/DoctorDetails';
import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import apiClient from 'services/apiClient';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Verify = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const query = useQuery();
  const userProfileId = query.get('UserProfileId');
  const appointmentId = query.get('AppointmentId');
  const timezone = query.get('timezone');
  const details = JSON.parse(decodeURIComponent(query.get('Details')));
  const [countries, setCountries] = useState([]);
  const [otpInput, setOtpInput] = useState('');
  const [verifyModal, setVerifyModal] = useState({ isVisible: false });
  const [loading, setLoading] = useState(false);
  const countryCode = useWatch({ control, name: 'countryCode' });
  const phoneNumber = useWatch({ control, name: 'phoneNumber' });
  const navigate = useNavigate();
  const getCountry = async () => {
    try {
      const response = await apiClient.get(
        `MasterApi/GetCountry?languageCode=${i18n.language}`
      );
      setCountries(response.data.Data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getCountry();
  }, []);
  const encodeDetails = (details) => {
    return encodeURIComponent(JSON.stringify(details));
  };
  const verifyOTP = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `AccountApi/VerifyOTP?PhoneNumber=${countryCode}${phoneNumber}&Otp=${otpInput}&OtpType=10&languageCode=${i18n.language}`
      );
      if (response.data.Status === 0) {
        toast.error(response.data.Message);
        return;
      }
      navigate(
        `/userregister?UserProfileId=${userProfileId}&AppointmentId=${appointmentId}&Details=${encodeDetails(details)}&timezone=${timezone}&countryCode=${countryCode}&phoneNumber=${phoneNumber}`
      );
    } catch (error) {
      console.error(error);
      alert('OTP verification failed. Please check your OTP and try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Initialize form values with existing data
  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `AccountApi/SendOTP?PhoneNumber=${formData.countryCode}${formData.phoneNumber}&OtpType=Signup&languageCode=${i18n.language}`
      );
      if (response.data.Status === 0) {
        toast.error(response.data.Message);
        return;
      }
      setVerifyModal({ isVisible: true });
    } catch (err) {
      console.error('Submission error:', err);
      // Handle submission error
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;

    // Allow only digits and limit to 6 characters
    if (/^\d{0,6}$/.test(value)) {
      setOtpInput(value);
    }
  };

  return (
    <>
      <DoctorDetails details={details} />
      <ToastContainer />
      <div className="pb-12">
        <div className="container md:w-[60%] w-[100%] mx-auto">
          <div className="border-[1px] border-[#C1C1C1] p-5 rounded-[8px]">
            <h2 className="text-[26px] font-semibold mb-2">
              {t('patient.about_you')}
            </h2>
            <p>
              {t('patient.verify_info', {
                doctorName: details?.DoctorName || 'the doctor',
              })}
            </p>
            {verifyModal?.isVisible ? (
              <div className="">
                <input
                  className="appearance-none rounded-lg w-full mt-5 leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4] cus_pad"
                  id="verifyOTP"
                  type="text"
                  placeholder={t('form.verify_otp')}
                  value={otpInput}
                  onChange={handleChange}
                />
                <button
                  className="w-full block text-center sb__button--secondary bg-[#0499A0] disabled:bg-[#9aa5b5] p-2 text-white rounded-[8px] mt-5 text-[20px] font-medium"
                  disabled={loading}
                  loading={loading}
                  onClick={verifyOTP}
                >
                  {t('buttons.submit')}
                </button>
              </div>
            ) : (
              <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
                  <div className="w-full">
                    <select
                      className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                      id="countryCode"
                      defaultValue=""
                      {...register('countryCode', {
                        required: `${t('form.error.countryCodeRequired')}`,
                      })}
                    >
                      <option value="" disabled>
                        {t('form.countryCode')}
                      </option>
                      {countries.map((country) => (
                        <option
                          className="text-[#000]"
                          key={country.CountryCode}
                          value={country.CountryCode}
                        >
                          +{country.CountryCode} {country.label}
                        </option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <p className="text-red-500">
                        {errors.countryCode.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <input
                      className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                      id="phoneNumber"
                      type="text"
                      placeholder={t('form.phoneNumber')}
                      {...register('phoneNumber', {
                        required: `${t('form.error.phoneNumberRequired')}`,
                        minLength: {
                          value: 10,
                          message: `${t('form.error.phoneNumberMinLength')}`, // e.g. "Phone number must be at least 10 digits."
                        },
                        maxLength: {
                          value: 12,
                          message: `${t('form.error.phoneNumberMaxLength')}`, // e.g. "Phone number cannot exceed 12 digits."
                        },
                        pattern: {
                          value: /^[0-9]*$/, // Only numbers allowed
                          message: `${t('form.error.phoneNumberInvalid')}`, // e.g. "Phone number must be numeric."
                        },
                      })}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500">
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  className="w-full block text-center sb__button--secondary bg-[#0499A0] disabled:bg-[#9aa5b5] p-2 text-white rounded-[8px] mt-5 text-[20px] font-medium"
                >
                  {t('form.send_otp')}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
