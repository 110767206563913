// components/Chat.js
import { faPaperPlane, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const Chat = ({ isOpen, onClose }) => {
  const [messages, setMessages] = useState([
    {
      sender: 'system',
      message:
        'Please provide your contact information, we will get in touch with you',
    },
  ]);
  const [value, setValue] = useState('');
  if (!isOpen) return null;
  const handleMessage = () => {
    if (!value) {
      return;
    }
    setMessages((prev) => [...prev, { sender: 'user', message: value }]);
    setValue('');
  };

  return (
    <div className="fixed bottom-5 right-5 bg-white border rounded-lg shadow-lg p-4 w-96 z-[999999]">
      <h4 className="font-semibold">Chat with Us</h4>
      <div className="h-64 overflow-y-auto border p-2 mt-3">
        {messages.map((e, index) => (
          <div
            key={index}
            className={`message ${e.sender === 'user' ? 'bg-grey user-message' : 'bg-green system-message'}`}
          >
            {e?.message}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-[80%_20%] gap-4 items-center">
        <textarea
          type="text"
          placeholder="Type your message..."
          className="w-full border rounded mt-2 p-2 "
          style={{ minHeight: '50px', maxHeight: '150px' }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <button
          className="bg-blue-500 text-white p-2 shadow-lg mr-4"
          onClick={handleMessage}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>

      <button
        onClick={onClose}
        className="text-gray-500 mt-2 h-[30px] w-[30px] bg-[#bfcbcb] absolute top-0 right-[22px]"
      >
        <FontAwesomeIcon icon={faX} />
      </button>
    </div>
  );
};

export default Chat;
