import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from 'assests/logos/footer-logo-1.png';
import apple from 'assests/logos/appstore-ios.png';
import googleplay from 'assests/logos/appstore-android.png';
import { useTranslation } from 'react-i18next';
const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-white hover:text-[#0bc4d9] transition-colors duration-300 mb-3"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-[#1B1D1F] text-white mt-6 py-12">
      <div className="container mx-auto px-6 py-6">
        <div className="grid md:grid-cols-3 grid-cols-2">
          <div className=" mb-6 md:mb-0">
            <img src={footerLogo} alt=" " className="w-[250px]" />
            <a
              href="https://apps.apple.com/in/app/planet-doctor/id6448985336"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={apple} alt=" " className="mt-5  w-[150px] " />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.planet_doctor"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googleplay} alt=" " className="mt-5  w-[150px] " />
            </a>
          </div>
          <div className=" mb-6 md:mb-0">
            <h4 className="font-bold text-lg mb-6">Quick Links</h4>
            <FooterLink
              to="https://planetdoctor.com/about-us"
              label={t('navbar.aboutUs')}
            />
            <FooterLink
              to="https://planetdoctor.com/why-planet-doctor"
              label={t('navbar.whyPlanetDoctor')}
            />
            <FooterLink
              to="https://planetdoctor.com/services"
              label={t('navbar.services')}
            />
            <FooterLink
              to="https://planetdoctor.com/blog"
              label={t('navbar.blog')}
            />
          </div>
          {/* <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h4 className="font-bold text-lg mb-6">Quick Contact</h4>
            <FooterLink to="/" label="Chat" />
            <FooterLink to="/" label="Tickets" />
            <FooterLink to="/" label="Knowledge Base" />
            <FooterLink to="/" label="Terms of Use" />
          </div> */}
          <div className="mb-6 md:mb-0">
            <h4 className="font-bold text-lg mb-6">Quick Connect</h4>
            <p>
              Planet Doctor
              <br />
              2645 Executive Park Dr, Weston, FL 33331
              <br />
              info@planetdoctor.com
              <br />
              +1 (754-778-6722)
            </p>
          </div>
        </div>
        <div className="flex mt-10 justify-between border-t-[1px] pt-10 border-[#ffffff24]">
          <p>
            {' '}
            &copy; {new Date().getFullYear()} Planet Doctor, Inc. All Rights
            Reserved.
          </p>
          <div className="flex justify-between items-center gap-5">
            <Link
              to="https://planetdoctor.com/terms-of-use/"
              className="border-r-[1px] pr-5"
            >
              Terms Of Use
            </Link>
            <Link
              to="https://planetdoctor.com/privacy-policy/"
              className="pr-5"
            >
              Privacy Policy{' '}
            </Link>
            {/* <Link to="#" className="border-r-[1px] pr-5">
              Community Standards
            </Link>
            <Link to="#" className="border-r-[1px] pr-5">
              Consumer Health
            </Link>
            <Link to="#">Contact</Link> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
