/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DoctorDetails from 'components/doctor-details/DoctorDetails';
import React, { useState, useEffect } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import apiClient from 'services/apiClient';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UserRegister = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const query = useQuery();
  const doctorProfileId = query.get('UserProfileId');
  const appointmentId = query.get('AppointmentId');
  const countryCode = query.get('countryCode');
  const phoneNumber = query.get('phoneNumber');
  const details = JSON.parse(decodeURIComponent(query.get('Details')));
  const [countries, setCountries] = useState([]);
  const [organization, setOrganization] = useState();
  const [timezones, setTimezones] = useState([]);
  const description = useWatch({ control, name: 'Description' });
  const [userLocation, setUserLocation] = useState({});
  const [selectedAddress, setSelectedAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    setValue('countryCode', countryCode);
    setValue('phoneNumber', phoneNumber);
  }, [countryCode, phoneNumber, countries, setValue]);

  const handlePlaceChange = (place) => {
    if (!place || !place.geometry) {
      return;
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    let city = '';
    let state = '';
    let country = '';
    let zipcode = '';
    const fullAddress = place.formatted_address;
    setSelectedAddress(fullAddress);
    place.address_components.forEach((component) => {
      const componentType = component.types[0];
      if (
        componentType === 'locality' ||
        componentType === 'sublocality' ||
        componentType === 'administrative_area_level_3'
      ) {
        city = component.long_name;
      }
      if (componentType === 'administrative_area_level_1') {
        state = component.long_name;
      }
      if (componentType === 'country') {
        country = component.long_name;
      }
      if (componentType === 'postal_code') {
        zipcode = component.long_name; // Zipcode
      }
    });
    setUserLocation({
      coordinates: { lat: lat, lng: lng },
      city,
      state,
      country,
      zipcode,
      fullAddress,
    });
  };

  const getOrganizationType = async () => {
    const response = await apiClient.post('MasterApi/GetOrgnaizationType', {
      languageCode: i18n.language,
    });
    setOrganization(response.data.Data[0]);
  };
  const getCountry = async () => {
    try {
      const response = await apiClient.get(
        `MasterApi/GetCountry?languageCode=${i18n.language}`
      );
      setCountries(response.data.Data);
    } catch (err) {
      console.error(err);
    }
  };

  const getTimezone = async () => {
    try {
      const response = await apiClient.post('MasterApi/TimeZone', {
        DeviceId: '',
        languageCode: i18n.language,
      });
      setTimezones(response.data.Data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOrganizationType();
    getCountry();
    getTimezone();
  }, []);

  // Initialize form values with existing data
  const onSubmit = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: t('form.error.passwordMismatch'),
      });
      return;
    }
    if (!selectedAddress) {
      // Set error for the address field
      setError('address', {
        type: 'manual',
        message: t('form.error.addressRequired'),
      });
      return;
    }
    const apiPayload = {
      TimeZone: formData?.timezone,
      AssosietedOrg: 'Yes',
      DeviceId: '',
      InsuranceProviderName: '',
      IsInsurance: '',
      License: '',
      NpiNo: '',
      PaypalEmail: '',
      PhysicianFee: '',
      PolicyNumber: '',
      ProviderType: '',
      ReferenceName: '',
      ReferenceType: '10',
      Speciality: '',
      AppointmentId: appointmentId || '',
      Description: formData.Description || '',
      DoctorUserProfileId: details?.DoctorProfileId || '',
      OrgLocId: organization?.Id || '',
      IsFolloupApptId: '',
      IsOnline: true,
      IsPCCAllow: false,
      roleID: '1',
      username: `${formData.countryCode}${formData.phoneNumber}`,
      UserProfileId: doctorProfileId || '',
      languageCode: i18n.language,
      email: formData.email || '',
      firstName: formData.firstName || '',
      lastName: formData.lastName || '',
      CountryCode: formData.countryCode || '',
      phoneno: formData.phoneNumber || '',
      Address: selectedAddress || '',
      Country: userLocation.country || '',
      State: userLocation.state || '',
      City: userLocation.city || '',
      ZipCode: userLocation.zipcode || '',
      dob: '',
      Gender: formData.gender || '',
      password: formData.password,
    };
    setLoading(true);
    try {
      const response = await apiClient.post(
        'AccountApi/PatientRegistration',
        apiPayload
      );
      if (response?.data?.Status === 0) {
        toast.error(response?.data?.Message);
        return;
      }
      bookAppointment(response?.data?.Data?.Patient?.UserProfileId);
    } catch (error) {
      console.error(error);
      alert('Failed to register patient. Please try again.');
      return null;
    } finally {
      setLoading(false);
    }
  };

  const bookAppointment = async (userProfileId) => {
    setLoading(true);
    const bookAppointmentData = {
      AppointmentId: appointmentId,
      Description: description,
      DoctorUserProfileId: doctorProfileId,
      IsFolloupApptId: '0',
      IsOnline: true,
      IsPCCAllow: false,
      UserProfileId: userProfileId,
      languageCode: i18n.language,
    };

    try {
      const response = await apiClient.post(
        'HomeApi/BookAppointment',
        bookAppointmentData
      );
      response?.data?.Status === 1 && processPayment(userProfileId);
    } catch (error) {
      console.error(error);
      alert('Failed to book appointment. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };
  const processPayment = async (registeredUserProfileId) => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `HomeApi/OnlinePaymentApp?planId=${appointmentId}&RequestType=1&UserProfileId=${registeredUserProfileId}&languageCode=${i18n.language}`
      );
      if (response?.data?.Status === 0) {
        toast.error(response?.data?.Message);
        return;
      }
      window.location.href = response.data.Data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const renderLabel = (label, isRequired) => (
    <label>
      {label}
      {isRequired && <span className="text-red-500">*</span>}
    </label>
  );
  return (
    <>
      <DoctorDetails details={details} />
      <ToastContainer />
      <div className="pb-12">
        <div className="container md:w-[60%] w-[100%]  mx-auto">
          <div className="border-[1px] border-[#C1C1C1] p-5 rounded-[8px]">
            <h2 className="text-[26px] font-semibold mb-2">
              {t('patient.about_you')}
            </h2>
            <p>
              {t('patient.verify_info', {
                doctorName: details?.DoctorName || 'the doctor',
              })}
            </p>
            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-4 mt-5">
                <div>
                  {renderLabel(t('form.countryCode'), true)}
                  <select
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 text-[#000] bg-[#a6a6a6] disabled:bg-[#b9c4d6]"
                    id="countryCode"
                    disabled
                    {...register('countryCode', {
                      required: `${t('form.error.countryCodeRequired')}`,
                    })}
                  >
                    <option value="" disabled>
                      {t('form.countryCode')}
                    </option>
                    {countries.map((country) => (
                      <option
                        className="text-[#000]"
                        key={country.CountryCode}
                        value={country.CountryCode}
                      >
                        +{country.CountryCode} {country.label}
                      </option>
                    ))}
                  </select>
                  {errors.countryCode && (
                    <p className="text-red-500">{errors.countryCode.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('form.phoneNumber'), true)}
                  <input
                    id="phoneNumber"
                    type="text"
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 text-[#000] bg-[#a6a6a6] disabled:bg-[#c5d0e0]"
                    disabled
                    placeholder={t('form.phoneNumber')}
                    {...register('phoneNumber', {
                      required: `${t('form.error.phoneNumberRequired')}`,
                    })}
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-500">{errors.phoneNumber.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('form.firstName'), true)}
                  <input
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                    id="fname"
                    type="text"
                    placeholder={t('form.firstName')}
                    {...register('firstName', {
                      required: `${t('form.error.firstNameRequired')}`,
                    })}
                  />
                  {errors.firstName && (
                    <p className="text-red-500">{errors.firstName.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('form.lastName'), true)}
                  <input
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                    id="lname"
                    type="text"
                    placeholder={t('form.lastName')}
                    {...register('lastName', {
                      required: `${t('form.error.lastNameRequired')}`,
                    })}
                  />
                  {errors.lastName && (
                    <p className="text-red-500">{errors.lastName.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('form.email'), true)}
                  <input
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                    id="email"
                    type="email"
                    placeholder={t('form.email')}
                    {...register('email', {
                      required: `${t('form.error.emailRequired')}`,
                    })}
                  />
                  {errors.email && (
                    <p className="text-red-500">{errors.email.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('globalSearch.enterLocation'), true)}
                  <ReactGoogleAutocomplete
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                    placeholder={t('globalSearch.enterLocation')}
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    onPlaceSelected={(place) => {
                      handlePlaceChange(place);
                      clearErrors('address');
                    }}
                    onChange={() => {
                      setSelectedAddress('');
                    }}
                    options={{
                      types: ['address'],
                    }}
                  />
                  {errors.address && (
                    <p className="text-red-500">{errors.address.message}</p>
                  )}
                </div>

                <div>
                  {renderLabel(t('form.gender'), true)}
                  <select
                    {...register('gender', {
                      required: `${t('form.error.genderRequired')}`,
                    })}
                    defaultValue=""
                    className="rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 text-[#000] bg-[#F4F4F4] disabled:bg-[#c5d0e0]"
                  >
                    <option value="" disabled>
                      {t('form.gender')}
                    </option>
                    <option value="M" className="text-[#000]">
                      {t('form.male')}
                    </option>
                    <option value="F" className="text-[#000]">
                      {t('form.female')}
                    </option>
                    <option value="O" className="text-[#000]">
                      {t('form.other')}
                    </option>
                  </select>

                  {errors.gender && (
                    <p className="text-red-500">{errors.gender.message}</p>
                  )}
                </div>
                <div>
                  {renderLabel(t('form.selectTimezone'), true)}
                  <select
                    {...register('timezone', {
                      required: `${t('form.error.timezoneRequired')}`,
                    })}
                    defaultValue=""
                    className="rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 text-[#000] bg-[#F4F4F4] disabled:bg-[#c5d0e0]"
                  >
                    <option value="" disabled>
                      {t('form.selectTimezone')}
                    </option>
                    {timezones.map((e) => (
                      <option value={e?.Name} className="text-[#000]">
                        {e?.Name}
                      </option>
                    ))}
                  </select>

                  {errors.timezone && (
                    <p className="text-red-500">{errors.timezone.message}</p>
                  )}
                </div>
                <div className="relative">
                  {renderLabel(t('form.password'), true)}
                  <input
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4] "
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={t('form.password')}
                    {...register('password', {
                      required: `${t('form.error.passwordRequired')}`,
                    })}
                  />
                  <button
                    onClick={togglePasswordVisibility}
                    type="button"
                    className="absolute top-[32px] right-[20px]"
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                  {errors.password && (
                    <p className="text-red-500">{errors.password.message}</p>
                  )}
                </div>
                <div className="relative">
                  {renderLabel(t('form.confirmPassword'), true)}
                  <input
                    className="appearance-none rounded-lg w-full leading-tight focus:outline-none focus:shadow-outline p-4 bg-[#F4F4F4]"
                    id="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={t('form.confirmPassword')}
                    {...register('confirmPassword', {
                      required: `${t('form.error.confirmPasswordRequired')}`,
                      onChange: (e) => {
                        const { value } = e.target;
                        const password = getValues('password'); // Get the current password value
                        if (value !== password) {
                          setError('confirmPassword', {
                            type: 'manual',
                            message: t('form.error.passwordMismatch'),
                          });
                        } else {
                          clearErrors('confirmPassword'); // Clear error if passwords match
                        }
                      },
                    })}
                  />
                  <button
                    onClick={toggleConfirmPasswordVisibility}
                    type="button"
                    className="absolute top-[32px] right-[20px]"
                  >
                    {showConfirmPassword ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <FontAwesomeIcon icon={faEye} />
                    )}
                  </button>
                  {errors.confirmPassword && (
                    <p className="text-red-500">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={loading}
                loading={loading}
                className="w-full block text-center sb__button--secondary bg-[#0499A0] disabled:bg-[#9aa5b5] p-2 text-white rounded-[8px] mt-5 text-[20px] font-medium"
              >
                {t('form.continue')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegister;
