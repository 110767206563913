import HeroCover from './components/hero-cover/HeroCover';
import { useState } from 'react';
import userRec from '../../assests/Rectangle_user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faStar } from '@fortawesome/free-solid-svg-icons';
import QR from '../../assests/qrcode_tweet_shader.png';
import apple from '../../assests/apple.png';
import playstore from '../../assests/playstore.png';
import mu from '../../assests/mu.png';
import Dentist from '../../assests/logos/Dentist.png';
import Dermatologist from '../../assests/logos/Dermatologist.png';
import EyeDoctor from '../../assests/logos/Eye-Doctor.png';
import OrthopedicSergeon from '../../assests/logos/Orthopedic-Surgeon.png';
import Pediatrician from '../../assests/logos/Pediatrician.png';
import Psychiatrist from '../../assests/logos/Psychiatrist.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Chat from './Chat';

const Home = () => {
  // State variables
  const [searchSpecialty, setSearchSpecialty] = useState('');
  const [isChatOpen, setChatOpen] = useState(false); // State to manage chat visibility
  // State for storing available cities

  /**
   * Queries the available cities based on the user's input.
   * @param {string} query - The user's input.
   * @returns {void}
   *
   */
  const onSearchSpecialtyChange = (value) => {
    setSearchSpecialty(value);
  };

  /**
   * Handles the click event of the search button.
   * It gathers the number of guests, check-in and check-out dates, and selected city
   * from the component's state, and then navigates to the '/hotels' route with this data.
   */
  const topSearchedSpecialties = [
    { img: Dentist, text: 'Dentist' },
    {
      img: Dermatologist,
      text: 'Dermatologist',
    },
    {
      img: Psychiatrist,
      text: 'Psychiatrist',
    },
    {
      img: EyeDoctor,
      text: 'Eye Doctor',
    },
    {
      img: Pediatrician,
      text: 'Pediatrician',
    },
    {
      img: OrthopedicSergeon,
      text: 'Orthopedic Surgeon',
    },
  ];
  const userReviews = [
    {
      name: 'Ana Rhodes',
      company: 'Google',
      rating: 4.3,
      review:
        'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
      img: userRec,
    },
    {
      name: 'Lina Delsey',
      company: 'Canonical',
      rating: 3.3,
      review:
        'While the service was adequate, I expected more based on the company’s reputation. There were delays in communication, and the final output didn’t meet my initial expectations. I hope they can improve in the future.',
      img: 'https://randomuser.me/api/portraits/women/1.jpg',
    },
    {
      name: 'Sam Brook',
      company: 'Honeybell',
      rating: 6.3,
      review:
        'Decent experience overall. The product met my needs, but there were a few hiccups along the way. Customer support was helpful but could be more responsive. I would consider using them again.',
      img: 'https://randomuser.me/api/portraits/men/1.jpg',
    },
    {
      name: 'Persey Dale',
      company: 'Vector',
      rating: 8.3,
      review:
        'Great service with a lot of useful features. The team was responsive and the product exceeded my expectations. A few minor issues arose, but they were handled efficiently. Highly recommend!',
      img: 'https://randomuser.me/api/portraits/women/2.jpg',
    },
    {
      name: 'Camey Johnson',
      company: 'Maersk',
      rating: 9.4,
      review:
        'Absolutely fantastic experience! Everything from onboarding to final delivery was seamless. The support team went above and beyond to assist me. I will definitely be coming back for more.',
      img: 'https://randomuser.me/api/portraits/men/2.jpg',
    },
    {
      name: 'Mooney Rain',
      company: 'Hewlett Packard Enterprise',
      rating: 5.6,
      review:
        'It was an average experience. While some aspects of the service were good, there were a few notable shortcomings. I think they have potential, but they need to focus on consistency.',
      img: 'https://randomuser.me/api/portraits/women/3.jpg',
    },
    {
      name: 'Simmi Grewal',
      company: 'Groww',
      rating: 8.7,
      review:
        'Impressive service! The team was knowledgeable and delivered on time. There were some features that could use enhancement, but overall, I was very satisfied with my experience.',
      img: 'https://randomuser.me/api/portraits/men/3.jpg',
    },
  ];

  const toggleChat = () => {
    setChatOpen((prev) => !prev);
  };
  return (
    <>
      <HeroCover
        searchSpecialty={searchSpecialty}
        onSearchSpecialtyChange={onSearchSpecialtyChange}
      />
      <div className="bg-[#EEF8F9] py-8">
        <div className="container mx-auto">
          <h2 className="text-center  text-[30px] font-semibold">
            Top-Searched <span className="text-[#0499A0]">Specialties</span>
          </h2>
          <span className="w-[150px] h-[5px] bg-[#0499A0] text-center block mx-auto mt-4"></span>
          <Swiper
            spaceBetween={10}
            loop={true}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {topSearchedSpecialties.map((e, index) => (
              <SwiperSlide className="slider" key={index}>
                <div className="border-2 border-[#70C6CA] p-5 text-center rounded-[8px] bg-gradient-to-t from-[#70C6CA] to-[#ffffff] min-h-[245px]">
                  <div className="p-4 bg-white rounded-full w-[120px] h-[120px] mx-auto mb-5 overflow-hidden flex items-center ">
                    {' '}
                    <img src={e?.img} alt="" />
                  </div>
                  <h3 className="text-[16px] text-[#fff] ">{e.text}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="py-8">
        <div className="container mx-auto">
          <h2 className="text-center  text-[30px] font-semibold">
            Reviews from <span className="text-[#0499A0]">Users</span>
          </h2>
          <span className="w-[150px] h-[5px] bg-[#0499A0] text-center block mx-auto mt-4"></span>
          <Swiper
            spaceBetween={10}
            loop={true}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {userReviews?.map((e, index) => (
              <SwiperSlide className="slider" key={index}>
                <div className="grid gap-4 items-center">
                  <div className="p-5 h-[350px] rounded-[8px] bg-[#E2F1F2] feedback">
                    <div className="grid grid-cols-[20%_45%_35%] gap-4 items-center mb-5 ">
                      <div>
                        <img src={e?.img} alt="" className="feed_img" />
                      </div>
                      <div>
                        <h3 className="text-[20px] text-[#33ACB2] font-bold">
                          {e?.name}
                        </h3>
                        <span className="text-[#B7B7B7]">{e?.company}</span>
                      </div>
                      <div>
                        <span className="bg-[#9AD5D8] text-[#ffffff] py-2 px-4 rounded-[30px]">
                          {e?.rating} <FontAwesomeIcon icon={faStar} />
                        </span>
                      </div>
                    </div>
                    <p>{e?.review}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="">
        <div className="container mx-auto cus_mobile_bg">
          <div className="grid md:grid-cols-2  grid-cols-1 gap-4 items-center">
            <div className="md:mb-0 mb-8">
              <h5 className="text-[30px] text-[#0499A0] font-semibold mb-8">
                Thousands of providers <br /> One app.
              </h5>
              <p className="text-[18px]">
                The PlanetDoctor app is the quickest, easiest way to book and
                keep track of your appointments.
              </p>
              <div className="grid md:grid-cols-[30%_30%] grid-cols-1 gap-4 items-center mt-5">
                <div>
                  <img src={QR} alt=" " />
                </div>
                <div>
                  <a
                    href="https://apps.apple.com/in/app/planet-doctor/id6448985336"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={apple} alt=" " className="mb-3" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.planet_doctor"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={playstore} alt=" " />
                  </a>
                </div>
              </div>
            </div>

            <div className="">
              <img src={mu} alt="" className="mx-auto block w-[40%]" />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={toggleChat}
        className="fixed bottom-5 right-5 bg-blue-500 text-white p-3 rounded-full shadow-lg z-50"
      >
        <FontAwesomeIcon icon={faCommentDots} />
      </button>

      {/* Chat component */}
      <Chat isOpen={isChatOpen} onClose={toggleChat} />
    </>
  );
};

export default Home;
